import React, { useEffect, useState } from 'react'
import { CardContainer, ProfileHeading } from '../Profile/StyledComponents'
import { SubHeading, UnderHeading, OtherHeading } from './StyledComponents'
import { IoInformationCircleOutline } from "react-icons/io5";
import axios from 'axios';
import { API_URLs } from '../../Utils/ApiUrls';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';

export default function Notifications() {
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [key, setKey] = useState('');


    const handleGetNotification = () => {
        axios.get(API_URLs.notificationSetting, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                console.log('res.data.data',res.data.data)
                setData(res.data.data);
                // console.log(res.data.data);
            })
            .catch((error) => {
                console.log('Error', error);
            })
    }

    const handleChange = (name, subname, value) => {
        let subObj = {};
        let obj = {};
        subObj[`${subname}`] = value
        obj[`${name}`] = subObj;

        axios.patch(API_URLs.notificationSetting, obj, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                handleGetNotification();
            })
            .catch((err) => {
                console.log('Error', err);
            })
    }
    const handleChange1 = (name, subname, cat, value) => {
        // console.log(name, subname, cat, value)
        const obj = {
            category: name,
            subCategory: subname
        }
        obj[`${cat}`] = value;
        axios.patch(API_URLs.subNotificationUpdate, obj, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                handleGetNotification();
            })
            .catch((err) => {
                console.log('Error', err);
            })
    }

    const handleChangeTradeNotificaton = (objName, value) => {
        const data = { [objName]: value };
        console.log('data>>>',data)
        axios.patch(API_URLs.notificationSetting, data, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })  
            .then((res) => {
                handleGetNotification();
            })
            .catch((err) => {
                console.log('Error', err);
            })
    }

    useEffect(() => {
        handleGetNotification();
    }, []);

    const otherSetting = [
        {
            name: 'Notification_Sound',
            type: "Play Notification sound for new messages and trade.",
            value: data?.Notification_Sound
        },
        {
            name: 'Receive_Trade_Popup',
            type: "Receive popup notification for incoming trades.",
            value: data?.Receive_Trade_Popup
        },
        {
            name: 'Receive_Trade_Page_Popup',
            type: "Receive popup notification for incoming trades on a trade page.",
            value: data?.Receive_Trade_Page_Popup
        },
    ]

    return (
        <div className='d-flex flex-column'>
            <CardContainer className='w-100 p-4 col-md-6'>
                <div className='d-flex justify-content-between w-100'>
                    <ProfileHeading className='w-50'>Notification Settings</ProfileHeading>
                    <div className='w-50 d-flex justify-content-between'>
                        <ProfileHeading className='fs-6'>E-Mail</ProfileHeading>
                        <ProfileHeading className='fs-6'>Push</ProfileHeading>
                    </div>
                </div>
                {
                    Object.entries(data).map((e, idx) => {
                        let name = e[0];
                        return <>{!(e[0]==='Receive_Trade_Popup' || e[0]==='Receive_Trade_Page_Popup' || e[0]==='Notification_Sound') && <hr />}
                          {!(e[0]==='Receive_Trade_Popup' || e[0]==='Receive_Trade_Page_Popup' || e[0]==='Notification_Sound') && <div className='d-flex mb-4 justify-content-between w-100'>
                                <div className='w-50'>
                                    <div className='d-flex gap-1 align-items-center'>
                                        <SubHeading className='m-0'>{e[0]?.split('_')[0]} {e[0]?.split('_')[1]}</SubHeading>
                                        <IoInformationCircleOutline color='#6F7182' />
                                    </div>
                                    {idx === 0 && <UnderHeading className='w-75 m-0'>Important security alerts like password resets.</UnderHeading>}
                                    {idx === 1 && <UnderHeading className='w-75 m-0'>Account activity like buyer, sells, transfer and rewards.</UnderHeading>}
                                </div>
                                <div className="form-check form-switch w-50 d-flex justify-content-between">
                                    <input
                                        className="form-check-input"
                                        type="checkbox" role="switch"
                                        checked={e[1].email}
                                        onChange={() => handleChange(e[0], 'email', !e[1].email)}
                                        id="flexSwitchCheckDefault"
                                    />
                                    <div className='d-flex'>
                                        <input
                                            className="form-check-input"
                                            type="checkbox" role="switch"
                                            checked={e[1].push}
                                            onChange={() => handleChange(e[0], 'push', !e[1].push)}
                                            id="flexSwitchCheckDefault"
                                        />
                                        <div role='button' onClick={() => { setShow(!show); setKey(e[0]) }}>{show && key === e[0] ? <IoIosArrowDown /> : <IoIosArrowUp />}</div>
                                    </div>
                                </div>
                            </div>}
                            {show && key === e[0] && <>{
                                Object.entries(e[1].subCategory).map((subData) => {
                                    return (<>
                                        <div key={uuidv4()} className='d-flex pt-2 justify-content-between w-100'>
                                            <div className='w-50'>
                                                <div className='d-flex gap-1 align-items-center'>
                                                    <SubHeading className='m-0'>{subData[0]?.split('_')[0]} {subData[0]?.split('_')[1]}</SubHeading>
                                                    <IoInformationCircleOutline color='#6F7182' />
                                                </div>
                                            </div>
                                            <div className="form-check form-switch w-50 d-flex justify-content-between">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox" role="switch"
                                                    checked={subData[1].email}
                                                    onChange={() => handleChange1(name, subData[0], 'email', !subData[1].email)}
                                                    id="flexSwitchCheckDefault"
                                                />
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox" role="switch"
                                                    checked={subData[1].push}
                                                    onChange={() => handleChange1(name, subData[0], 'push', !subData[1].push)}
                                                    id="flexSwitchCheckDefault"
                                                />
                                            </div>
                                        </div></>
                                    );
                                })
                            }</>}
                        </>
                    })
                }
            </CardContainer>
            <CardContainer className='mt-2 px-4 py-3'>
                {
                    otherSetting?.map((data, idx) => {
                        return <>
                            <div key={uuidv4()} className='d-flex '>
                                <input 
                                    type='checkbox' 
                                    checked={data?.value} 
                                    onChange={() => handleChangeTradeNotificaton(data?.name, !data?.value)} 
                                />
                                <OtherHeading className='mb-0 mx-2'>{data?.type}</OtherHeading>
                            </div>
                            <hr />
                        </>
                    })
                }
            </CardContainer>
        </div>
    )
}

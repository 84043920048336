import React, { useState, useCallback, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component';
import { BiCopy } from 'react-icons/bi'
import QRCode from "react-qr-code";
import axios from 'axios';
import { IoIosCheckmarkCircle } from "react-icons/io";
import CustomModal from '../../hooks/CustomModal';
import InviteFriendImg from "../../assets/Images/inviteFriend.png"
import EarnImg from "../../assets/Images/Earn.png"
import styled from 'styled-components';
import { FaRegQuestionCircle, FaInstagram } from "react-icons/fa";
import { EmailShareButton, FacebookShareButton, InstapaperShareButton, LinkedinShareButton, RedditShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, XIcon, TelegramIcon, FacebookIcon, LinkedinIcon, RedditIcon, WhatsappIcon, EmailIcon } from "react-share";
import "./Refer.css";
import { useAuthContextHook } from '../../Context/AuthContext';
import { API_URLs } from '../../Utils/ApiUrls';
import { useThemeContext } from '../../Context/ThemeContext';
import { Link } from 'react-router-dom';

const Span = styled.p`
font-size: 25px;
font-weight: 700;
font-family: 'Inter';
margin-bottom: 0px;
text-align: center;
`
const P = styled.p`
font-size: 13px;
font-family: 'Inter';
margin-bottom: 0px;
font-weight: 500;
text-align: center;
`
const Div1 = styled.div`
padding: 10px;
background:  ${({ theme }) => theme.body === '#F7F9FC' ? 'white' : theme.body};
border-radius: 20px;
border:  ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
`
const Div2 = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
`
const ReferLabel = styled.label`
color:#6F7182;
font-size: 14px;
font-family: 'Inter';
font-weight: 600;
`
const DivWithRefer = styled.div`
display: flex;
justify-content: center;
align-items: center;
background: transparent;
padding: 5px;
box-shadow: ${({ theme }) => theme.boxshadow};
border: ${({ theme }) => theme.border};
border-radius: 15px;
`
const InputWithRefer = styled.input`
width: 85%;
border: none;
background: transparent;
outline: none;
padding: 0px;
`
const Qrdiv = styled.div`
box-shadow: 0px 0px 10px lightgrey;
border-radius: 13px;
padding: 10px;
background: white;
`
const ClainButton = styled.button`
border: none;
border-radius: 10px;
background: #1476FF;
color: white;
outline: none;
font-size: 15px;
font-weight: 600;
`
const ClainButtonAll = styled.button`
border: 1px solid #E9EAF3;
box-shadow: ${({ theme }) => theme.boxshadow};
border-radius: 20px;
background: white;
color: black;
outline: none;
width: 8rem;
font-size: 15px;
font-weight: 600;
transition-duration: 0.7s;
padding: 10px 2px;
:hover{
    background: #1476FF;
    opacity: 0.9;
    color: white;
    transition-duration: 0.7s;
}
`
const Div3 = styled.div`
box-shadow: ${({ theme }) => theme.boxshadow};
border: ${({ theme }) => theme.border};
background:  ${({ theme }) => theme.body === '#F7F9FC' ? 'white' : theme.body}; 
`

export default function Reffer() {
    const [copySuccess, setCopySuccess] = useState('');
    const { userDetails } = useAuthContextHook();
    const { isDarkTheme } = useThemeContext();
    const [l1Earn, setL1Earn] = useState(0)
    const [l2Earn, setL2Earn] = useState(0)
    const [referTxn, setReferTxn] = useState([])
    const [topFiverefer, setTopFiverefer] = useState([]);
    const [myReferal, setmyReferal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const copyToClipBoard = useCallback(async (copyMe) => {
        setCopySuccess('');
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    }, [])



    useEffect(() => {
        let l1Length = userDetails?.user?.referralCode?.tierOneReferrals?.length;
        let sum = 0;
        for (let i = 0; i < l1Length; i++) {
            sum += userDetails?.user?.referralCode?.tierOneReferrals[i].referredUserAmount;
        }
        setL1Earn(sum)

        let l2Length = userDetails?.user?.referralCode?.tierTwoReferrals?.length;
        sum = 0;
        for (let i = 0; i < l2Length; i++) {
            sum += userDetails?.user?.referralCode?.tierTwoReferrals[i].referredUserAmount;
        }
        setL2Earn(sum)
    }, [userDetails])

    const handleGetAllReferalTxn = () => {
        axios.get(API_URLs.getAllReferTxn, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {

                let sample = ['ETH', 'BTC', 'MATIC', 'USDC', 'USDT'];
                let data = [];
                for (let i = 0; i < sample.length; i++) {
                    let name = sample[i]

                    let obj = {
                        currency: name,
                        affiliate: res?.data?.affiliate?.balance[name],
                        own: res?.data?.own?.balance[name],
                        balance: res?.data?.balance[name]
                    }
                    data.push(obj);
                }
                setReferTxn(data)
            })
            .catch((error) => {

            })
    }

    const handleGetTop5Refer = () => {
        axios.get(API_URLs.getTop5Refer, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setTopFiverefer(res.data);
            })
            .catch((error) => {
            })
    }

    const handleGetMyreferals = () => {
        axios.get(API_URLs.myReferalUser, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setmyReferal(res?.data?.result?.data);
            })
            .catch((error) => {
                console.log('Error', error);
            })
    }

    useEffect(() => {
        handleGetAllReferalTxn();
        handleGetTop5Refer();
        handleGetMyreferals();
    }, [])

    const handleClainAll = (option) => {
        axios.post(API_URLs.clainAll(option), {}, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Claimed Successfully!!')
                setNavigateLink('')
            })
            .catch((error) => {
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!')
                setNavigateLink('')
            })
    }
    const columns = [
        {
            name: <p className='mb-0 fw-bold'>Currency</p>,
            selector: (row) => <div>
                {row?.currency}
            </div>
        },
        {
            name: <p className='mb-0 fw-bold'>Affiliate Amount <FaRegQuestionCircle title='User signup from your code, you get 30% on every successfull trade. ' /> </p>,
            selector: (row) => <div>
                {row?.affiliate} {row?.currency}
            </div>
        },
        {
            name: <p className='mb-0 fw-bold'>Own Amount <FaRegQuestionCircle title="If you came here from someone's refer, you get 10% on each successfull trade." /></p>,
            selector: (row) => <div>
                {row?.own} {row?.currency}
            </div>
        },
        {
            name: <p className='mb-0 fw-bold'>Total Amount <FaRegQuestionCircle title='It is the total of your earning (Affiliate + Own)' /></p>,
            selector: (row) => <div>
                {row?.balance} {row?.currency}
            </div>
        },
        {
            name: <p className='mb-0 fw-bold'>Action</p>,
            cell: (row) => <div onClick={() => handleClainAll(row?.currency)}>
                <ClainButton>Claim</ClainButton>
            </div>
        }
    ]
    const topFiveColumn = [
        {
            name: <p className='mb-0 fw-bold'>Name</p>,
            selector: (row) => <div>
                {row?.username || row?.nickNames || 'N/A'}
            </div>
        },
        {
            name: <p className='mb-0 fw-bold'>No. of Refers</p>,
            selector: (row) => <div>
                {row?.referalcodeLength || 'N/A'}
            </div>
        },
        {
            name: <p className='mb-0 fw-bold'>Earning</p>,
            selector: (row) => <div>
                {row?.ConvertUsdAmount?.toFixed(4) || 0} USD
            </div>
        }
    ]
    const myReferalColumn = [
        {
            name: <p className='mb-0 fw-bold'>ID</p>,
            selector: (row) => <div>
                {row?._id?.slice(0, 6)?.concat('...')}
            </div>
        },
        {
            name: <p className='mb-0 fw-bold'>Name</p>,
            selector: (row) => <div>
                {row?.nickNames || 'N/A'}
            </div>
        },
        {
            name: <p className='mb-0 fw-bold'>Earning</p>,
            selector: (row) => <div>
                {row?.amountInUsdtire1?.toFixed(4) || 0} USD
            </div>
        }
    ]
    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...(isDarkTheme ? { borderTop: '1px solid #302f30' } :
                    { border: '1px solid lightgray' }),
                color: `${isDarkTheme ? "#e9e9e9" : '#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d" : "#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }

    return (
        <>
            <div className='row m-0'>
                <div className='col-md-7 '>
                    <Div1 className='reffertable'>
                        <Span className='text-start'>Share & Earn 35$</Span>
                        <div className='d-flex flex-row justify-content-around'>
                            <img src={InviteFriendImg} width='100' height='100' alt="InviteFriendImg" />
                            <img src={EarnImg} width='100' height='100' alt="EarnImg" />
                        </div>
                        <div className='row m-0 mt-2'>
                            <div className='col-md-6 d-flex flex-column'>
                                <P className='text-start ps-2'> <IoIosCheckmarkCircle size={17} color='#1476FF' /> Every time your affiliates buy or sell a crypto and complete a transaction successfully, you'll earn up to 30% of their Trading fee.</P>
                            </div>
                            <div className='col-md-6 d-flex flex-column'>
                                <P className='text-start ps-2'> <IoIosCheckmarkCircle size={17} color='#1476FF' /> When people invited by your friends buy or sell a cryptocurrency on Qvani, Earn 10% of their Trading Fee.</P>
                                <P className='text-start ps-2'> <IoIosCheckmarkCircle size={17} color='#1476FF' />  Your affiliates will receive 10% discount on trading fees for 6 months.</P>
                            </div>
                        </div>
                    </Div1>
                    <Div1 className='reffertable mt-2'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <Span>Reward Balance</Span>
                        </div>
                        <div className='d-flex flex-column  justify-content-center mt-3'>
                            <div className='d-flex justify-content-center pb-2'>
                                <P className='fw-bold fs-4 me-5'>${userDetails?.user?.referralCode?.referralAmount} <span className='fw-normal'>USD</span></P>
                            </div>
                        </div>
                        <Div2 className='tab-navigate mt-2'>
                            <ul id='myTab' className="nav nav-tabs d-flex justify-content-center">
                                <li className="nav-item" >
                                    <button
                                        className='nav-link active'
                                        id="lone-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#lone"
                                        type="button"
                                        role="tab"
                                        aria-controls="lone"
                                        aria-selected="true">
                                        L1 {"(30%)"}
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className='nav-link'
                                        id="ltwo-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#ltwo"
                                        type="button"
                                        role="tab"
                                        aria-controls="ltwo"
                                        aria-selected="true">
                                        L2 {"(10%)"}
                                    </button>
                                </li>
                            </ul>
                            <div className='tab-content' id='myTabContent'>
                                <div className='tab-pane fade py-3 pb-0 px-2 show active'
                                    id="lone"
                                    role="tabpanel"
                                    aria-labelledby="lone-tab">
                                    <div className='d-flex w-100 align-items-center justify-content-between mb-2'>
                                        <ReferLabel className='w-50' htmlFor='cryptoAmount'>Earnings</ReferLabel>
                                        <P className='fw-bold fs-6 me-5'>${l1Earn} </P>
                                    </div>
                                    <div className='d-flex w-100 align-items-center justify-content-between mb-2'>
                                        <ReferLabel className='w-50' htmlFor='cryptoAmount'>Partners</ReferLabel>
                                        <P className='fw-bold fs-6 me-5'>{userDetails?.user?.referralCode?.tierOneReferrals?.length} </P>
                                    </div>
                                </div>
                                <div className='tab-pane fade py-3 pb-0 px-2'
                                    id="ltwo"
                                    role="tabpanel"
                                    aria-labelledby="ltwo-tab">
                                    <div className='d-flex w-100 align-items-center justify-content-between mb-2'>
                                        <ReferLabel className='w-50' htmlFor='cryptoAmount'>Earnings</ReferLabel>
                                        <P className='fw-bold fs-6 me-5'>${l2Earn} </P>
                                    </div>
                                    <div className='d-flex w-100 align-items-center justify-content-between mb-2'>
                                        <ReferLabel className='w-50' htmlFor='cryptoAmount'>Partners</ReferLabel>
                                        <P className='fw-bold fs-6 me-5'>{userDetails?.user?.referralCode?.tierTwoReferrals?.length} </P>
                                    </div>
                                </div>
                            </div>
                        </Div2>
                    </Div1>
                </div>
                <div className='col-md-5 mt-2 mt-md-0'>
                    <Div1 className='card p-3'>
                        <Span className='fs-5' style={{ color: "#6F7182" }}>INVITE VIA LINK</Span>
                        <hr />
                        <div>
                            <div className='d-flex w-100 align-items-center justify-content-between mb-4'>
                                <ReferLabel className='w-50' htmlFor='cryptoAmount'>Referral Code</ReferLabel>
                                <DivWithRefer className='w-50'>
                                    <InputWithRefer
                                        placeholder={`${userDetails?.user?.referralCode?.referralCode}`}
                                    // placeholder={`${myReferal?.referralCode}`}
                                    />
                                    <BiCopy onClick={() => copyToClipBoard(userDetails?.user?.referralCode?.referralCode)} role='button' title='copy' size={17} />
                                    <small className='fw-bold'>{copySuccess}</small>
                                </DivWithRefer>
                            </div>
                            <div className='d-flex w-100  justify-content-between mb-4'>
                                <ReferLabel className='w-50' htmlFor='cryptoAmount'>Share With friends</ReferLabel>
                                <div className='w-50'>
                                    <div className='d-flex flex-wrap justify-content-between mb-2'>
                                        <TwitterShareButton
                                            url={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                            title={`Qvani Exchange is an online crypto buy/sell platform. You can also earn some crypto on every trade. Use my Referal Code ${userDetails?.user?.referralCode?.referralCode} to register, and get some free crypto in your wallet.`}
                                        >
                                            <XIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </TwitterShareButton>
                                        {/* <Link to={'https://twitter.com/QvaniApp'} target='_blank'>
                                            <XIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </Link> */}

                                        <TelegramShareButton
                                            url={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                            title={`Qvani Exchange is an online crypto buy/sell platform. You can also earn some crypto on every trade. Use my Referal Code ${userDetails?.user?.referralCode?.referralCode} to register, and get some free crypto in your wallet.`}
                                        >
                                            <TelegramIcon size={32} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </TelegramShareButton>
                                        {/* <Link to={'https://t.me/+lIWgDUoFOwNkNzJh'} target='_blank'>
                                            <TelegramIcon size={32} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </Link> */}

                                        {/* <InstapaperShareButton
                                            
                                            url={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                            url={`https://www.instagram.com/qvaniapp/`} target='_blank'
                                            title={`Qvani Exchange is an online crypto buy/sell platform. You can also earn some crypto on every trade. Use my Referal Code ${userDetails?.user?.referralCode?.referralCode} to register, and get some free crypto in your wallet.`} >
                                                
                                        </InstapaperShareButton> */}
                                        <Link to={'https://www.instagram.com/qvaniapp/'} target='_blank'>
                                            <FaInstagram size={23} color='#1476FF' />
                                        </Link>

                                        <FacebookShareButton
                                            url={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                            title={`Qvani Exchange is an online crypto buy/sell platform. You can also earn some crypto on every trade. Use my Referal Code ${userDetails?.user?.referralCode?.referralCode} to register, and get some free crypto in your wallet.`} >
                                            <FacebookIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </FacebookShareButton>

                                        {/* <Link to={'https://www.facebook.com/QvaniApp'} target='_blank'>
                                            <FacebookIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />

                                        </Link> */}
                                    </div>


                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <LinkedinShareButton
                                            url={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                            title='Qvani Referal Code'
                                            summary={`Qvani Exchange is an online crypto buy/sell platform. You can also earn some crypto on every trade. Use my Referal Code ${userDetails?.user?.referralCode?.referralCode} to register, and get some free crypto in your wallet.`}
                                            source='Qvani Exchange'>
                                            <LinkedinIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </LinkedinShareButton>

                                        {/* <Link to={'https://www.linkedin.com/company/qvaniapp'} target='_blank'>
                                            <LinkedinIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </Link> */}

                                        <RedditShareButton
                                            url={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                            title={`Qvani Exchange is an online crypto buy/sell platform. You can also earn some crypto on every trade. Use my Referal Code ${userDetails?.user?.referralCode?.referralCode} to register, and get some free crypto in your wallet.`}>
                                            <RedditIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </RedditShareButton>

                                        {/* <Link to={'https://www.reddit.com/r/QvaniApp/'} target='_blank'>
                                        <RedditIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </Link> */}


                                        <WhatsappShareButton
                                            url={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                            title={`Qvani Exchange is an online crypto buy/sell platform. You can also earn some crypto on every trade. Use my Referal Code ${userDetails?.user?.referralCode?.referralCode} to register, and get some free crypto in your wallet.`} >
                                            <WhatsappIcon size={32} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </WhatsappShareButton>

                                        <EmailShareButton
                                            url={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                            subject='Qvani Referal Code'
                                            body={`Qvani Exchange is an online crypto buy/sell platform. You can also earn some crypto on every trade. Use my Referal Code ${userDetails?.user?.referralCode?.referralCode} to register, and get some free crypto in your wallet.`}>
                                            <EmailIcon size={38} round={true} bgStyle={{ fill: `${isDarkTheme ? 'black' : 'white'}` }} iconFillColor='#1476FF' />
                                        </EmailShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <Span className='fs-5' style={{ color: "#6F7182" }}>INVITE VIA QR CODE</Span>
                        <hr />
                        <div className='d-flex justify-content-center align-items-center'>
                            <Qrdiv className={!isDarkTheme && 'border border-1'}>
                                <QRCode
                                    size={175}
                                    value={`https://qvani.com/signup?referalcode=${userDetails?.user?.referralCode?.referralCode}`}
                                />
                            </Qrdiv>
                        </div>
                    </Div1>
                </div>
            </div>
            <Div3 className='card mt-3 py-1 px-0'>
                <div className='d-flex justify-content-between align-items-center m-2'>
                    <p className='fw-bold p-2 mb-0'>Reward Balance</p>
                    <ClainButtonAll onClick={() => handleClainAll("ALL")}>
                        Claim ALL
                    </ClainButtonAll>
                </div>
                <DataTable
                    style={{ overflow: 'none !important' }}
                    customStyles={customStyles}
                    columns={columns}
                    data={referTxn}
                    theme='solarized'
                />
            </Div3>
            <div className='row m-0 d-flex justify-content-between'>
                <div className='col-md-5 px-0 mt-3'>
                    <Div1 className='py-1 px-0'>
                        <p className='fw-bold p-2 mb-0'>Qvani WorldWide LeaderBoard</p>
                        <DataTable
                            data={topFiverefer?.result}
                            customStyles={customStyles}
                            columns={topFiveColumn}
                            theme='solarized'
                        />
                    </Div1>
                </div>
                <div className='col-md-5 px-0 mt-3'>
                    <Div1 className='py-1 px-0'>
                        <p className='fw-bold p-2 mb-0'>My Refers</p>
                        <DataTable
                            data={myReferal?.tierOneReferrals}
                            customStyles={customStyles}
                            columns={myReferalColumn}
                            theme='solarized'
                        />
                    </Div1>
                </div>
            </div>

            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData} />
            )}
        </>
    );
}